import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts/default';
import FundIntro from '../components/FundIntro';
import MoreCafes from '../components/MoreCafes';
import PostList from '../components/PostList';

const IndexPage = ({ data, searchQuery }) => (
  <Layout>
    <FundIntro
      className="landingSection"
    />
    <MoreCafes
      className="landingSection"
      cafes={data.takeshape.cafes.items}
      desktopLimit={6}
      shuffle={false}
      headerCopy={'Cafes'}
    />
    <PostList
      className="landingSection morePosts"
      headerCopy="The ComeTogether Blog"
      posts={data.takeshape.posts}
      size={3}
      h2
      showCta
    />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    takeshape {
      cafes: getCafeList(
        sort: { order: "DESC", field: "_enabledAt" }
        size: 250
      ) {
        items {
          name
          cafeUrl
          cardBio
          coverImage {
            path
          }
          location
          shortLocation
          shortName
          status: statusHtml
          howToHelp: howToHelpHtml
          sellingCoffee
          searchTerms
        }
      }
      posts: getPostList(sort: [{field: "_enabledAt", order: "desc"}]) {
        items {
          _id
          _enabledAt
          title
          deck
          featuredImage {
            path
          }
          cardImage {
            path
          }
          author {
            name
          }
        }
      }
    }
  }
`;
